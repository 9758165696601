import { OptionalLink } from '@/components/OptionalLink';
import { useAppState } from '@/hooks/useAppState';
import { MabraStandaloneBadge } from '@/mabra/components/Badge';
import type { StandaloneComponent } from '@/types/component';
import withTrackingAttributes from '@/utils/withTrackingAttributes';
import { StandaloneArticleTeaserDefaultProps } from 'base/components/ArticleTeaser/Default';
import { ArticleTeaser, ArticleTeaserProvider } from 'base/components/ArticleTeaser/Default/ArticleTeaser';
import Tracking from 'base/components/TrackingComponent';
import { ArticleBadgeData } from 'lib/labrador/articleBadge';
import { mergeProps } from 'lib/utils/merge';

export interface MabraStandaloneArticleTeaserProps extends StandaloneArticleTeaserDefaultProps {
  badge?: ArticleBadgeData;
  articleType?: string;
}

export const MabraStandaloneArticleTeaserDefault: StandaloneComponent<MabraStandaloneArticleTeaserProps> = ({
  caption,
  description,
  footer,
  header,
  headline,
  image,
  kicker,
  links,
  badge,
  articleType,
  options,
  ...props
}) => {
  const [{ metaPageType }] = useAppState();
  const { $caption, $description, $footer, $group, $header, $headline, $image, ...$base } = options ?? {};
  const { colors, size, variant, theme } = $base ?? {};

  const ctsAttrs =
    metaPageType === 'courses'
      ? withTrackingAttributes({
          object: 'teaser',
          creative: 'teaser',
          objectName: headline as string,
          name: headline as string,
          objectExtraInfo: [{ campaign: 'mabra_course_sep24', type: articleType }],
          label: [{ campaign: 'mabra_course_sep24', type: articleType }],
          objectUrl: links?.article?.href,
          id: links?.article?.href,
        })
      : {};

  return (
    <ArticleTeaserProvider value={{ colors, size: size ?? variant, variant, theme }}>
      <Tracking options={{ ctsAttrs }}>
        <ArticleTeaser {...$base} {...props}>
          {header && <ArticleTeaser.Header {...$header}>{header}</ArticleTeaser.Header>}
          <OptionalLink
            {...mergeProps(
              {
                options: {
                  className: variant === 'horizontal' ? 'col-span-2' : '',
                },
              },
              links?.article,
            )}
            content={<ArticleTeaser.Image {...mergeProps({ options: $image }, image)} />}
          />
          {badge && <MabraStandaloneBadge data={badge} variant="teaser" />}
          <ArticleTeaser.Group {...$group}>
            {caption && (
              <OptionalLink
                {...links?.caption}
                content={<ArticleTeaser.Caption {...$caption}>{caption}</ArticleTeaser.Caption>}
              />
            )}
            {headline && (
              <OptionalLink
                {...links?.article}
                content={<ArticleTeaser.Headline {...$headline}>{headline}</ArticleTeaser.Headline>}
              />
            )}
            {description && <ArticleTeaser.Description {...$description}>{description}</ArticleTeaser.Description>}
            {kicker && <ArticleTeaser.Description {...$description}>{kicker}</ArticleTeaser.Description>}
          </ArticleTeaser.Group>
          {footer && <ArticleTeaser.Footer {...$footer}>{footer}</ArticleTeaser.Footer>}
        </ArticleTeaser>
      </Tracking>
    </ArticleTeaserProvider>
  );
};
