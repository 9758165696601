/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { MabraStandaloneArticleTeaserDefault as ResolvedStandaloneArticleTeaserDefault } from 'sites/mabra/components/standalone/ArticleTeaser/Default';

export const ArticleTeaserDefault = ResolvedStandaloneArticleTeaserDefault;

export type ArticleTeaserDefaultProps = PropsFromComponent<typeof ArticleTeaserDefault>;

export const ArticleTeaserDefaultWith = (extras: DynamicStandaloneExtras): typeof ArticleTeaserDefault => {
    return function ArticleTeaserDefault(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ArticleTeaserDefault');
        return <ResolvedStandaloneArticleTeaserDefault {...mergeProps({ options: { theme } }, props)} />;
    }
}