import React, { cloneElement, FC, isValidElement, ReactNode } from 'react';
import withTrackingAttributes from '@/utils/withTrackingAttributes';
import { mergeProps } from '@/utils/merge';

interface TrackingProps {
  children: ReactNode;
  options?: {
    ctsAttrs?: ReturnType<typeof withTrackingAttributes>;
    wrapChildren?: boolean;
  };
}

const groupProps = { 'data-cts-object-group': '', className: 'cts-impression-group' };
const itemProps = { className: 'cts-impression-item' };

const Tracking: FC<TrackingProps> = ({ children, options }) => {
  const { ctsAttrs, wrapChildren } = options ?? {};

  const elementsWithTracking = React.Children.map(children, (child) => {
    if (isValidElement(child)) {
      const shouldAddGroupProps = !wrapChildren && !ctsAttrs;
      const newProps = wrapChildren
        ? mergeProps({ ...itemProps, ...ctsAttrs }, child.props)
        : mergeProps({ ...(shouldAddGroupProps ? groupProps : itemProps), ...ctsAttrs }, child.props);

      const newElement = cloneElement(child, newProps);

      if (wrapChildren) {
        return <div {...groupProps}>{newElement}</div>;
      }

      return newElement;
    }

    return child;
  });

  return <>{elementsWithTracking}</>;
};

export default Tracking;
